export default {
  getEntity: (state) => (entityName, id) => {
    if(!state[entityName]?.data && !state[entityName + "s"]?.data) return

    return state[entityName]?.data[id] || state[entityName + "s"]?.data[id]
  },
  getEntities: (state) => (entityName, ids) => {
    if(!state[entityName]?.data) return []

    if(ids){
      let arr = ids
      if(typeof ids === "string"){
        arr = state[entityName].pages?.[ids]?.items || []
      }
      return arr.map(id => {
        return state[entityName].data[id] || id
      })
    }

    return Object.keys(state[entityName].data).map(id => {
      return state[entityName].data[id] || id
    })
  },
  getMeta: (state) => (entityName, pageName) => {
    return state[entityName]?.[pageName]?.meta || {}
  }
}
