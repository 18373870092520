import mutations from "@/_evoli/store/snackbar/mutations";
import getters from "@/_evoli/store/snackbar/getters";
import state from "@/_evoli/store/snackbar/state";

export default {
  namespaced: true,
  state: { ...state },
  getters,
  mutations,
};
