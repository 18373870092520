import form from "@/_evoli/store/form/form";
import auth from "@/_evoli/store/auth/auth";
import entities from "@/_evoli/store/entities/entities";
import snackbar from "@/_evoli/store/snackbar/snackbar";
import socket from "@/_evoli/store/socket/socket";
import tickets from "@/_evoli/store/tickets/tickets";

export default {
  form,
  auth,
  entities,
  snackbar,
  socket,
  tickets
};
