import permission from "@/_evoli/helper/permission";
import opinionsLinks from "@/aquana/helper/container/opinionsLinks";

export default (store, list) => {
  if (permission(store, ["ANON"])) {
    return {}
  }

  let links = {
    ...opinionsLinks(store, list),
    treatments: {},
    testOrders: {},
    conclusion: {},
  };

  if (store.state.filterHeaders?.[list].includes("conclusion.name"))
    delete links.conclusion;

  if (store.state.filterHeaders?.[list].includes("treatments"))
    delete links.treatments;

  if (store.state.filterHeaders?.[list].includes("testOrders"))
    delete links.testOrders;


  return links;
};
