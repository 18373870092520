export default [
  {
    path: "costbearers",
    name: "Kostenträger",
    meta: {
      authorize: true,
      roles: ["MEDEXO"],
    },
    component: () =>
      import(
        /* webpackChunkName: "costbearers" */ "@/aquana/views/account/costbearers/CostBearerContainer.vue"
      ),
  },
  {
    path: "costbearers/:id",
    name: "Kostenträger",
    meta: {
      authorize: true,
      roles: ["MEDEXO"],
      entity: "costBearers",
    },
    component: () =>
      import(
        /* webpackChunkName: "costbearers" */ "@/aquana/views/account/costbearers/CostBearerForm.vue"
      ),
  },
  {
    path: "users",
    name: "Nutzer",
    meta: {
      authorize: true,
      roles: ["ADMIN"],
    },
    component: () =>
      import(
        /* webpackChunkName: "users" */ "@/aquana/views/account/users/UsersContainer.vue"
      ),
  },
  {
    path: "billers",
    name: "Rechnungssteller",
    meta: {
      authorize: true,
      roles: ["MEDEXO"],
    },
    component: () =>
      import(
        /* webpackChunkName: "billers" */ "@/aquana/views/account/billers/BillersContainer.vue"
      ),
  },
  {
    path: "practitioners",
    name: "Behandler",
    meta: {
      authorize: true,
      roles: ["MEDEXO"],
    },
    component: () =>
      import(
        /* webpackChunkName: "practitioners" */ "@/aquana/views/account/practitioners/PractitionersContainer.vue"
      ),
  },
  {
    path: "clinics",
    name: "Kliniken",
    meta: {
      authorize: true,
      roles: ["MEDEXO"],
    },
    component: () =>
      import(
        /* webpackChunkName: "clinics" */ "@/aquana/views/account/clinics/ClinicsContainer.vue"
      ),
  },
  {
    path: "experts",
    name: "Experten",
    meta: {
      authorize: true,
      roles: ["MEDEXO"],
    },
    component: () =>
      import(
        /* webpackChunkName: "experts" */ "@/aquana/views/account/experts/ExpertsContainer.vue"
      ),
  },
  {
    path: "experts/:id",
    name: "Experte",
    meta: {
      authorize: true,
      roles: ["MEDEXO"],
      entity: "experts",
    },
    component: () =>
      import(
        /* webpackChunkName: "experts" */ "@/aquana/views/account/experts/ExpertsForm.vue"
      ),
  },
  {
    path: "profil/:id",
    name: "Profil",
    meta: {
      authorize: true,
      roles: ["EXPERT"]
    },
    component: () =>
      import(
        /* webpackChunkName: "experts" */ "@/aquana/views/account/experts/ExpertsForm.vue"
      ),
  },
];
