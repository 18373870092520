export default [
  {
    path: "goaenumbers",
    name: "GOÄ Nummern",
    meta: {
      authorize: true,
      roles: ["MEDEXO"],
    },
    component: () =>
      import(
        /* webpackChunkName: "goaenumbers" */ "@/aquana/views/config/goaenumbers/GoaeNumbersContainer.vue"
      ),
  },
  // {
  //   path: "recommendations",
  //   name: "Empfehlungen",
  //   meta: {
  //     authorize: true,
  //     roles: ["ADMIN"],
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "recommendations" */ "@/aquana/views/config/recommendations/RecommendationsContainer.vue"
  //     ),
  // },
  // {
  //   path: "pricepackages",
  //   name: "Preispakete",
  //   meta: {
  //     authorize: true,
  //     roles: ["ADMIN"],
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "pricepackages" */ "@/aquana/views/config/pricepackages/PricePackagesContainer.vue"
  //     ),
  // },
  {
    path: "servicepackages",
    name: "Honorarpakete",
    meta: {
      authorize: true,
      roles: ["ADMIN"],
    },
    component: () =>
      import(
        /* webpackChunkName: "servicepackages" */ "@/aquana/views/config/servicepackages/ServicePackagesContainer.vue"
      ),
  },
  {
    path: "treatments",
    name: "Behandlungen",
    meta: {
      authorize: true,
      roles: ["ADMIN"],
    },
    component: () =>
      import(
        /* webpackChunkName: "treatments" */ "@/aquana/views/config/treatments/TreatmentsContainer.vue"
      ),
  },
  {
    path: "diagnoses",
    name: "Diagnosen",
    meta: {
      authorize: true,
      roles: ["MEDEXO"],
    },
    component: () =>
      import(
        /* webpackChunkName: "diagnoses" */ "@/aquana/views/config/diagnoses/DiagnosesContainer.vue"
      ),
  },
  {
    path: "icds",
    name: "ICD Codes",
    meta: {
      authorize: true,
      roles: ["MEDEXO"],
    },
    component: () =>
      import(
        /* webpackChunkName: "icds" */ "@/aquana/views/config/icds/IcdsContainer.vue"
      ),
  },
  {
    path: "textblocks",
    name: "Textblöcke",
    meta: {
      authorize: true,
      roles: ["MEDEXO"],
    },
    component: () =>
      import(
        /* webpackChunkName: "textblocks" */ "@/aquana/views/config/textblocks/TextBlocksContainer.vue"
      ),
  },
  {
    path: "motds",
    name: "Statusmeldungen",
    meta: {
      authorize: true,
      roles: ["ADMIN"],
    },
    component: () =>
      import(
        /* webpackChunkName: "motds" */ "@/aquana/views/config/motds/MotdsContainer.vue"
      ),
  },
  {
    path: "testorders",
    name: "Prüfaufträge",
    meta: {
      authorize: true,
      roles: ["ADMIN"],
    },
    component: () =>
      import(
        /* webpackChunkName: "testorders" */ "@/aquana/views/config/testorders/TestOrdersContainer.vue"
      ),
  },
  {
    path: "subjects",
    name: "Fachbereiche",
    meta: {
      authorize: true,
      roles: ["ADMIN"],
    },
    component: () =>
      import(
        /* webpackChunkName: "subjects" */ "@/aquana/views/config/subjects/SubjectsContainer.vue"
      ),
  },
  {
    path: "subjects/:id",
    name: "Fachbereich",
    meta: {
      authorize: true,
      roles: ["ADMIN"],
      entity: "subjects",
    },
    component: () =>
      import(
        /* webpackChunkName: "subjects" */ "@/aquana/views/config/subjects/SubjectsForm.vue"
      ),
  },
  {
    path: "emailtemplates",
    name: "E-Mail Vorlagen",
    meta: {
      authorize: true,
      roles: ["MEDEXO"],
    },
    component: () =>
      import(
        /* webpackChunkName: "emailtemplates" */ "@/aquana/views/config/emailtemplates/EmailTemplatesContainer.vue"
      ),
  },
  {
    path: "emailtemplates/:id",
    name: "E-Mail Vorlage",
    meta: {
      authorize: true,
      roles: ["MEDEXO"],
      entity: "emailTemplates",
    },
    component: () =>
      import(
        /* webpackChunkName: "emailtemplates" */ "@/aquana/views/config/emailtemplates/EmailTemplatesForm.vue"
      ),
  },
  {
    path: "conclusions",
    name: "Fazite",
    meta: {
      authorize: true,
      roles: ["ADMIN"],
    },
    component: () =>
      import(
        /* webpackChunkName: "conclusions" */ "@/aquana/views/config/conclusions/ConclusionsContainer.vue"
      ),
  },
  {
    path: "questionnaires",
    name: "Fragebogen",
    meta: {
      authorize: true,
      roles: ["MEDEXO"],
    },
    component: () =>
      import(
        /* webpackChunkName: "conclusions" */ "@/aquana/views/config/questionnaires/QuestionnairesContainer.vue"
      ),
  },
  {
    path: "questionnaires/:id",
    name: "Fragebogen",
    meta: {
      authorize: true,
      roles: ["MEDEXO"],
      entity: "questionnaires",
    },
    component: () =>
      import(
        /* webpackChunkName: "subjects" */ "@/aquana/views/config/questionnaires/QuestionnairesForm.vue"
      ),
  },
];
