import permission from "@/_evoli/helper/permission";
import opinionsHeaders from "@/aquana/helper/container/opinionsHeaders";

export default (store) => {
  let result = opinionsHeaders(store)

  if (permission(store, ["MEDEXO"])) {
    result.splice(9, 0, {
      text: "Behandlungen",
      value: "treatments",
      sortable: false,
    },
    {
      text: "Prüfanträge",
      value: "testOrders",
      sortable: false,
    })

    result.splice(17, 0, {
      text: "Fazit",
      value: "conclusion.name"
    })

    result.splice(22, 0, {
      label: "Gebührenprüfung",
      value: "goae",
      sortable: false,
    })
  }

  return result;
};
