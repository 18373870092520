import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import themes from "@/_evoli/helper/themes";

import de from "@/_evoli/helper/locale/de";
import en from "@/_evoli/helper/locale/en";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: localStorage.getItem("darkmode") === "false" ? false : process.env.VUE_APP_DARK === "true",
    options: {
      customProperties: true,
    },
    themes: themes(localStorage.getItem("theme") || process.env.VUE_APP_THEME),
  },
  lang: {
      locales: { de, en },
      current: localStorage.getItem("lang") || "de",
    },
  breakpoint: {
    thresholds: {
      lg: 1950,
    },
  },
});
