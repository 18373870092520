export default {
  init({ commit }, payload) {
    Object.keys(payload.item).some((key) => {
      commit("change", {
        form: payload.form,
        key,
        value: payload.item[key],
        init: typeof payload.init !== "undefined" ? payload.init : true,
      });
    });
  },
};
