<template>
  <v-app ondragstart="return false;" ondrop="return false;">
    <SnackBar />
    <router-view />
    <v-fab-transition>
      <v-btn
        v-if="showToTop"
        color="accent"
        fixed
        right
        bottom
        small
        fab
        @click="onToTop"
      >
        <v-icon>mdi-arrow-up-bold</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-app>
</template>

<script>
import SnackBar from "@/_evoli/components/site/SnackBar";

export default {
  components: {
    SnackBar
  },
  data() {
    return {
      showToTop: false
    }
  },
  created(){
    window.addEventListener('scroll', this.onScroll)
  },
  methods: {
    onToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    onScroll(){
      const scrollTop = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      )

      if(scrollTop > 100){
        this.showToTop = true
      } else {
        this.showToTop = false
      }
    },
  }
}
</script>
