import mutations from "@/_evoli/store/socket/mutations";
import getters from "@/_evoli/store/socket/getters";
import actions from "@/_evoli/store/socket/actions";

import router from "@/_evoli/router/index";
import { refresh } from "@/_evoli/helper/endpoint.js";

export function getSocketDefaultState() {
  return {
    openBy: [],
    userDashboard: [],
    count: {},
    anonStatus: {},
    analyzeProgress: {}
  }
}

export default {
  namespaced: true,
  state: getSocketDefaultState(),
  getters,
  mutations,
  actions
};

export function socketPlugin() {
  return store => {
    // if(!refreshToken){
      store.subscribe(({type}) => {
        if(type === "addRefreshToken" && !store.$socket){
          socketPlugin()(store)
        }
      })
      //   return
      // }
      
      const socket = new WebSocket(process.env.VUE_APP_SOCKETURL)
      store.$socket = socket
      
      socket.onopen = () => {
        store.dispatch("socket/SEND_route", router.currentRoute)
      }
      
    socket.onclose = () => {
      const refreshToken = store.getters.getRefreshToken
      store.$socket = null
      if(refreshToken){
        refresh(store)
      }
    };

    
    socket.onmessage = ({ data }) => {
      let parsed = JSON.parse(data);
      store.dispatch("socket/RECIEVE_" + parsed.type, parsed)

      // let loadActive = this.$refs.routerView?.$children.map(item => {
      //   return item.loadActive
      // }).filter(e => e)
      
      // let parsed = JSON.parse(data);
      // switch (parsed.type) {
      //   case "count":
      //     store.commit("setCountList", parsed);
      //     if (loadActive.length > 0){
      //       loadActive.forEach(item => {
      //         item()
      //       })
      //     }
      //     break;
      // }

      
    };
  } 
}
