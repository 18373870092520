import Vue from "vue";

export default {
  addEntities(state, payload) {
    let obj = { pages: {}, data: {}, ...state[payload.entityName] };
    let pageName = payload.params && payload.params.pageName;
    if (payload.params && pageName) {
      obj[pageName] = {
        items: payload.items,
        meta: { ...payload.meta, pageName },
      };

      obj.pages[pageName] = {
        items: payload.items.map(e => e._id || e),
        meta: { ...payload.meta, pageName },
      };

    } else {
      obj.items = payload.items;
    }

    payload.items.forEach(item => {
      obj.data[item._id] = item
    });

    Vue.set(state, payload.entityName, obj);
  },
  addEntity(state, payload) {
    let obj = { data: {}, ...state[payload.entityName] };
    obj.data[payload.item._id] = payload.item;
    obj[payload.item._id] = payload.item;
    Vue.set(state, payload.entityName, obj);
  },
  updateEntity(state, payload) {
    let obj = { data: {}, ...state[payload.entityName] };
    obj.data[payload.values._id] = {
      ...obj.data[payload.values._id],
      ...payload.values,
    };
    obj[payload.values._id] = {
      ...obj[payload.values._id],
      ...payload.values,
    };
    Vue.set(state, payload.entityName, obj);
  },
};
