import permission from "@/_evoli/helper/permission";
import opinionsHeaders from "@/aquana/helper/container/opinionsHeaders";

export default (store) => {
  let result = opinionsHeaders(store)

  if (permission(store, ["MEDEXO"])) {

    result.splice(15, 0, {
      text: "Versicherungstarif",
      value: "goae",
      sortable: false,
    },{
      text: "Verfahren",
      value: "proceed",
    })

    result.splice(22, 0, {
      label: "Medizinische Burteilung",
      value: "shortOpinion",
      sortable: false,
    })
  }
  
  return result;
}
