export default {
  checkLoading: (state) => (method, route) => {
    if(method && route){
      return state.loading[method].includes(route)
    }
    return Object.keys(state.loading).some((key) => {
      return state.loading[key].length > 0
    })
  },
  getMotd(state) {
    return state.motd
  },
  getProgress: (state) => (type) => {
    return state.progress[type] || 0
  },
  getLastActivity(state) {
    return state.lastActivity
  },
  getServiceNotAvailable(state) {
    return state.serviceNotAvailable
  },
  getFilterStatus: (state) => (list) => {
    return state.filterStatus[list.name]
  },
  getFilterHeaders: (state) => (list) => {
    return state.filterHeaders[list.name]
  },
  getFilterList: (state) => (list) => {
    return state.filterList[list.name || list]
  },
  checkDeleteSearch: (state) => (list) => {
    return !state.deleteSearch.includes(list.name)
  },
}
