<template>
  <div>
    <v-snackbar
      v-if="show"
      v-model="show"
      shaped
      top
      :color="snackbar.color"
      vertical
      :timeout="snackbar.callback || snackbar.persistent ? -1 : 5000 "
    >
      <div
        :class="
          snackbar.callback ? 'content-width snackbar-flex' : 'snackbar-flex'
        "
      >
        <v-icon v-if="snackbar.icon" class="mr-2">
          {{ snackbar.icon }}
        </v-icon>
        <p class="text-body-1" style="white-space: pre-line">
          {{snackbar.content}}
        </p>
      </div>
      <template v-if="snackbar.callback" v-slot:action="{ attrs }">
        <div>
          <v-btn
            id="toast-false-btn"
            v-bind="attrs"
            v-on:click="() => onButtonClick(false)"
            outlined
            class="mb-2"
          >
            {{ snackbar.closeLabel }}
          </v-btn>
          <v-btn
            id="toast-true-btn"
            class="mx-3 mb-2"
            color="default"
            v-bind="attrs"
            v-on:click="() => onButtonClick(true)"
          >
            {{ snackbar.buttonLabel }}
          </v-btn>
        </div>
      </template>
      <template v-else-if="snackbar.persistent && snackbar.color !== 'error'" v-slot:action="{ attrs }">
        <div>
          <v-btn
            id="toast-close-btn"
            v-bind="attrs"
            v-on:click="show = false"
            outlined
            class="mb-2 mx-3"
          >
            {{ snackbar.closeLabel }}
          </v-btn>
        </div>
      </template>
    </v-snackbar>
    <v-overlay v-if="snackbar.callback" :value="snackbar.callback"></v-overlay>
  </div>
</template>

<script>
export default {
  computed: {
    show: {
      get() {
        return this.snackbar.show;
      },
      set() {
        this.$store.commit("snackbar/close");
      },
    },
    snackbar() {
      return this.$store.getters["snackbar/get"]()
    },
  },
  methods: {
    onButtonClick(value) {
      let callback = this.snackbar.callback;
      this.$store.commit("snackbar/close");
      callback(value);
    },
    onCloseClick() {
      this.$store.commit("snackbar/close");
    },
  },
};
</script>

<style lang="scss">
.v-snack__wrapper {
  min-width: 0 !important;
}
.content-width {
  min-width: 300px;
}
.v-snack__action {
  margin-bottom: 0px !important;
}
.snackbar-flex {
  display: flex;
  align-items: center;
}
</style>
