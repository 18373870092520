import permission from "@/_evoli/helper/permission";
import opinionsLinks from "@/aquana/helper/container/opinionsLinks";

export default (store, list) => {
  if (permission(store, ["ANON"])) {
    return {}
  }

  let links = {
    ...opinionsLinks(store, list),
  }

  return links;
};
