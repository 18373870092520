import permission from "@/_evoli/helper/permission";
import contains from "@/_evoli/helper/contains";

export default (store, list) => {
  if (permission(store, ["ANON"])) {
    return {}
  }

  let links = {
    costBearer: {},
    contactPerson: {},
    supervisor: {},
    subject: {},
    policyHolder: {},
    expert: {},
    pricePackage: {},
    servicePackage: {},
  }

  if (!permission(store, ["MEDEXO", "COSTBEARER"])) {
    delete links.contactPerson
  }
  
  if (!permission(store, ["MEDEXO"])) {
    delete links.costBearer
    delete links.expert
    delete links.pricePackage
    delete links.servicePackage
    delete links.supervisor
  }

  if (store.state.filterHeaders?.[list].includes("expert")){
    delete links.expert
  }

  if (store.state.filterHeaders?.[list].includes("subject.name"))
    delete links.subject;

  if (store.state.filterHeaders?.[list].includes("costBearer.name"))
    delete links.costBearer;

  if (store.state.filterHeaders?.[list].includes("contactPerson"))
    delete links.contactPerson;

  if (store.state.filterHeaders?.[list].includes("supervisor"))
    delete links.supervisor;

  if (store.state.filterHeaders?.[list].includes("pricePackage.name"))
    delete links.pricePackage;

  if (store.state.filterHeaders?.[list].includes("servicePackage.name"))
    delete links.servicePackage;

  if (
    contains(
      store.state.filterHeaders?.[list],
      [
        "policyHolder.birthDate",
        "policyHolder.gender",
        "policyHolder.insuranceNumber",
      ],
      true
    )
  )
    delete links.policyHolder;

  return links;
};
