import mutations from "@/_evoli/store/entities/mutations";
import actions from "@/_evoli/store/entities/actions";
import getters from "@/_evoli/store/entities/getters";

export default {
  state: {},
  getters,
  mutations,
  actions,
};
