import mutations from "@/_evoli/store/auth/mutations";
import actions from "@/_evoli/store/auth/actions";
import getters from "@/_evoli/store/auth/getters";

export function getAuthDefaultState() {
  return {
    accessToken: sessionStorage.getItem("accessToken"),
    refreshToken: localStorage.getItem("refreshToken"),
    tfaTelephone: "",
    tfaMethod: localStorage.getItem("tfaMethod") || "APP",
    self: {}
  }
}

export default {
  state: getAuthDefaultState(),
  getters,
  mutations,
  actions
};
