import Vue from "vue";
import App from "./App.vue";
import router from "@/_evoli/router";
import store from "@/_evoli/store";
import vuetify from "@/_evoli/plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "@/_evoli/style/index.scss";
import { VueMaskDirective } from "v-mask";
import { VueReCaptcha } from "vue-recaptcha-v3";
import VueMermaidString from "vue-mermaid-string";
import uploader from "vue-simple-uploader";
import VueSanitize from "vue-sanitize";
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

Vue.use(VueLodash, { lodash: lodash })
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA });
Vue.use(VueMermaidString);
Vue.use(uploader);
Vue.use(VueSanitize);

Vue.directive("mask", VueMaskDirective);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
