import Vue from "vue";

export default {
  addLoading(state, payload) {
    Vue.set(state.loading, payload.method, [
      ...state.loading[payload.method],
      payload.route,
    ]);
    if (payload.skeleton)
      Vue.set(state.skeleton, payload.method, [
        ...state.skeleton[payload.method],
        payload.route,
      ]);
  },
  removeLoading(state, payload) {
    setTimeout(function () {
      let indexLoading = state.loading[payload.method].indexOf(payload.route);
      let loading = state.loading[payload.method];
      delete loading[indexLoading];
      Vue.set(
        state.loading,
        payload.method,
        loading.filter((e) => e)
      );

      if (payload.skeleton) {
        let indexSkeleton = state.skeleton[payload.method].indexOf(
          payload.route
        );
        let skeleton = state.skeleton[payload.method];
        delete skeleton[indexSkeleton];
        Vue.set(
          state.skeleton,
          payload.method,
          skeleton.filter((e) => e)
        );
      }
    }, 1000);
  },
  setProgress(state, {type, value}) {
    Vue.set(state.progress, type, value);
  },
  setDialog(state, value) {
    Vue.set(state, "dialog", value);
  },
  setMinimized(state, value) {
    Vue.set(state, "minimized", value);
  },
  setMotd(state, value) {
    Vue.set(state, "motd", value);
  },
  setTextblocksDrawer(state, value) {
    Vue.set(state, "textBlocksDrawer", value);
  },
  setFilterStatus(state, payload) {
    Vue.set(state.filterStatus, payload.key, payload.items);
    localStorage.setItem("filterStatus." + payload.key, payload.items);
  },
  setFilterHeaders(state, payload) {
    Vue.set(state.filterHeaders, payload.key, payload.items);
    localStorage.setItem("filterHeaders." + payload.key, payload.items);
  },
  setOutsideMenu(state, payload) {
    Vue.set(state.outsideMenu, payload.key, payload.items);
    localStorage.setItem("outsideMenu." + payload.key, payload.items);
  },
  setFilterList(state, payload) {
    Vue.set(state.filterList, payload.key, payload.values);
  },
  setCountList(state, payload) {
    Vue.set(state.countList, payload.list, payload.count);
  },
  setLists(state, value) {
    Vue.set(state, "lists", value);
  },
  setNavigation(state, value) {
    Vue.set(state, "navigation", value);
  },
  setLastActivity(state, value) {
    Vue.set(state, "lastActivity", value);
  },
  setFixedOpinionTitle(state, value) {
    Vue.set(state, "fixedOpinionTitle", value)
    localStorage.setItem("fixedOpinionTitle", value)
  },
  addDeleteSearch(state, list) {
    let result = [...state.deleteSearch, list.name]
    localStorage.setItem("deleteSearch", result)
    Vue.set(state, "deleteSearch", result)
  },
  removeDeleteSearch(state, list) {
    let result = state.deleteSearch.filter(e => e !== list.name)
    localStorage.setItem("deleteSearch", result)
    Vue.set(state, "deleteSearch", result)
  },
  setOpinionRedirectOverview(state, value) {
    localStorage.setItem("opinionRedirectOverview", value)
    Vue.set(state, "opinionRedirectOverview", value)
  },
  setServiceNotAvailable(state, value) {
    Vue.set(state, "serviceNotAvailable", value)
  }
};
