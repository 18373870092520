import endpoint from "@/_evoli/helper/endpoint.js";

export default (store, ressource, links, skeleton) => {
  return assign(store, ressource, links, skeleton);
};

async function assign(store, ressource, links, skeleton) {
  if (Array.isArray(ressource)) {
    for (let item of ressource) {
      for (let link of Object.keys(links || {})) {
        item = await getLinkedRessource(store, item, link, links, skeleton);
      }
    }
    return ressource;
  } else {
    for (let link of Object.keys(links || {})) {
      ressource = await getLinkedRessource(
        store,
        ressource,
        link,
        links,
        skeleton
      );
    }
    return Promise.all([ressource]).then((result) => result[0]);
  }
}

async function getLinkedRessource(store, item, link, links, skeleton) {
  if(Array.isArray(item[link]) && item[link].length < 1){
    return []
  }

  if(!item.links && item.items && item.items.length > 0){
    item.items = await Promise.all(item.items.map(async (e) => {
      return await getLinkedRessource(store, e, link, links, skeleton)
    }))
    return item.items
  } else if (item.links && item.links[link]) {
    if(store.state[link]){
      let route = item.links[link].split("/")
      let id = route[route.length -1]
      let regex = /^[a-f\d]{24}$/i
      let found = false
      if(regex.test(id)){
        found = store.getters.getEntity(link, id)
      } else if(item[link] && Array.isArray(item[link])){
        found = item[link].map(e => {
          return store.getters.getEntity(link, e)
        })
      }

      if (found && (Array.isArray(found) ? !found.includes(undefined) : true) && Object.keys(links[link].links || {}).length > 0) {
        await assign(store, found, links[link].links);
      }

      if(found){
        if(Array.isArray(found) && !found.includes(undefined)){
          return item
        } else if(!Array.isArray(found)){
          return item
        }
      }
    }

    return endpoint(
      store,
      "GET",
      item.links[link],
      { limit: 0, ...links[link].params },
      null,
      skeleton
    ).then(async (response) => {
      if (Object.keys(links[link].links || {}).length > 0) {
        await assign(store, response.items || response, links[link].links);
      }

      if (response._id) {
        item[link] = response._id;
        store.commit("addEntity", {
          entityName: link,
          _id: response._id,
          item: response,
        });
      } else if(response.items) {
        item[link] = response.items.map((e) => e._id);
        response.items.forEach((item) => {
          store.commit("addEntity", {
            entityName: link,
            _id: item._id,
            item,
          });
        });
      }

      return item;
    });
  } else {
    return item;
  }
}
