export default {
  content: null,
  callback: null,
  buttonLabel: "Okay",
  closeLabel: "Schließen",
  color: "info",
  icon: null,
  show: false,
  persistent: false
};
