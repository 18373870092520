import store from "@/_evoli/store"
import shortOpinionsHeaders from "@/aquana/helper/container/shortOpinionsHeaders"
import shortOpinionsLinks from "@/aquana/helper/container/shortOpinionsLinks"

export default [
  {
    path: "shortopinions",
    name: "Medizinische Beurteilungen",
    meta: {
      authorize: true,
      lists: [
        {
          name: "shortOpinionsList",
          entityName: "shortOpinions",
          getHeaders: () => shortOpinionsHeaders(store),
          getLinks: () => shortOpinionsLinks(store, "shortOpinionsList")
        }
      ]
    },
    component: () =>
      import(
        /* webpackChunkName: "shortopinions" */ "@/aquana/views/shortopinions/ShortOpinionContainer.vue"
      ),
  },
  {
    path: "shortopinions/:id",
    name: "Medizinische Beurteilung",
    meta: {
      authorize: true,
      entityName: "shortOpinions",
    },
    component: () =>
      import(
        /* webpackChunkName: "shortopinions" */ "@/aquana/views/shortopinions/ShortOpinionForm.vue"
      ),
  },
];
