import Vue from "vue";
import defaultState from "@/_evoli/store/snackbar/state";

export default {
  open(state, payload) {
    Object.assign(state, defaultState);
    if (typeof payload === "object") {
      Vue.set(state, "content", payload.content);
      Vue.set(state, "callback", payload.callback);
      if (payload.buttonLabel)
        Vue.set(state, "buttonLabel", payload.buttonLabel);
      if (payload.closeLabel) Vue.set(state, "closeLabel", payload.closeLabel);
      if (payload.color) Vue.set(state, "color", payload.color);
      if (payload.icon) Vue.set(state, "icon", payload.icon);
      if (payload.persistent) Vue.set(state, "persistent", payload.persistent);
      Vue.set(state, "show", true);
    } else {
      Vue.set(state, "content", payload);
      Vue.set(state, "show", true);
    }
  },
  close(state) {
    Object.assign(state, defaultState);
  },
};
