export default {
  getOpenBy: (state) => (route) => {
    if(!route){
      return state.openBy
    }
    return state.openBy[route]
  },
  getUserDashboard(state) {
    return state.userDashboard?.dashboard || {}
  },
  getCount: (state) => (list) => {
    return state.count[list]
  },
  getAnonStatus: (state) => (id) => {
    if(!state.anonStatus[id]) return 
    switch (state.anonStatus[id]) {
      case "error":
        return "Es ist ein Fehler aufgetreten. Stoppen Sie die Pseudonymisierung und versuchen Sie es später erneut."
      case "nofile":
        return ""
      default:
        return Math.round(Number(state.anonStatus[id]) * 100)
    } 
  },
  getAnalyzeProgress(state) {
    return state.analyzeProgress
  }  
}
