import en from "vuetify/lib/locale/en";

let portal;
try {
  if(process.env.VUE_APP_PORTAL === "aquana"){
    portal = require('@/aquana/helper/locale/de').default;
  }
  if(process.env.VUE_APP_PORTAL === "flamara"){
    portal = require('@/flamara/helper/locale/de').default;
  }
} catch (e) {
  console.error(e);
}

export default {
  ...en,
  ...portal,
  time: "Monday to Friday | 9:00 a.m. to 6:00 p.m",
  telephone: "+49 30 555 7053 0",
  fax: "+49 30 555 7053 99",
  address: "Danziger Straße 104 | 10405 Berlin",
  addressTwoLine: "Danziger Straße 104 \n 10405 Berlin",
  email: "service@medexo.com",
}
