import Vue from "vue";

export default {
  addAccessToken(state, payload) {
    Vue.set(state, "accessToken", payload);
  },
  removeAccessToken(state) {
    Vue.set(state, "accessToken", null);
  },
  addRefreshToken(state, payload) {
    Vue.set(state, "refreshToken", payload);
  },
  removeRefreshToken(state) {
    Vue.set(state, "refreshToken", null);
  },
  setSelf(state, payload) {
    Vue.set(state, "self", payload);
  },
  setTfaTelephone(state, payload) {
    Vue.set(state, "tfaTelephone", payload);
  },
  setTfaMethod(state, payload) {
    Vue.set(state, "tfaMethod", payload);
    localStorage.setItem("tfaMethod", payload);
  },
  addRegistrationToken(state, payload) {
    Vue.set(state, "registrationToken", payload);
  },
};
