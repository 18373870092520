import endpoint from "@/_evoli/helper/endpoint.js"
import router from "@/_evoli/router/index";

export default {
  loginSimple(store, values) {
    return endpoint(store, "POST", "/v1/login/simple", {}, {
      ...values,
      service: process.env.VUE_APP_USER_SERVICE
    }).then(
      (response) => {
        store.commit("setTfaTelephone", response.telephone);
      }
    );
  },
  sms(store, values) {
    return endpoint(store, "POST", "/v1/sms", {}, {
      ...values,
      service: process.env.VUE_APP_USER_SERVICE
    });
  },
  loginTFA(store, values) {
    return endpoint(store, "POST", "/v1/login/tfa", {}, {
      ...values,
      service: process.env.VUE_APP_USER_SERVICE
    }).then(
      (response) => {
        sessionStorage.setItem("accessToken", response.accessToken);
        localStorage.setItem("refreshToken", response.refreshToken);
        store.commit("addAccessToken", response.accessToken);
        store.commit("addRefreshToken", response.refreshToken);
      }
    );
  },
  passwordResetEmail(store, values) {
    return endpoint(store, "POST", "/v1/passwordreset", {}, values);
  },
  passwordReset(store, values) {
    return endpoint(
      store,
      "POST",
      "/v1/passwordreset/reset",
      {},
      values
    );
  },
  logout(store) {
    const refreshToken = localStorage.getItem("refreshToken")
    if (store.state.self && refreshToken) {
      endpoint(store, "POST", "/v1/logout", {}, {refreshToken})
    }
    sessionStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    store.commit("removeAccessToken");
    store.commit("removeRefreshToken");
    store.commit("form/clear");
    store.commit("setSelf", {});
    store.commit("resetState")
    this.$socket.close()
    if(router.history.current.path !== "/login"){
      router.push("/login")
    }
  },
  self(store) {
    return endpoint(store, "GET", "/v1/users/self?service=" + process.env.VUE_APP_USER_SERVICE).then((response) => {
      store.commit("setSelf", response);
    });
  },
  confirmPatient(store) {
    return endpoint(store, "POST", "/v1/patients/confirm", {}, {service:"patient"}).then((response) => {
      sessionStorage.setItem("accessToken", response.accessToken);
      localStorage.setItem("refreshToken", response.refreshToken);
      store.commit("addAccessToken", response.accessToken);
      store.commit("addRefreshToken", response.refreshToken);
    })
  }
};
