import Vue from "vue";

export default {
  addTicket(state, id) {
    Vue.set(state, "tickets", [...state.tickets, id]);
  },
  removeTicket(state, id) {
    Vue.set(state, "tickets", state.tickets.filter(e => e !== id));
  },
};
