import colors from "vuetify/lib/util/colors";

export const themes = [
  {
    name: "Blau",
    dark: {
      primary: colors.cyan.darken2,
      secondary: colors.orange.darken2,
      accent: colors.deepPurple.accent1,
      chart: colors.yellow.accent1,
      default: colors.blueGrey.lighten2,
      background: colors.grey.darken4,
      menuBackground: "#1e1e1e",
      card: colors.blueGrey.darken4,
      toolbar: colors.blueGrey.darken2,
      info: colors.indigo.accent2,
      warning: colors.orange.darken2,
      success: colors.green.darken1,
      font: "#fff",
      scrollbar: colors.grey.lighten1,
      marked: colors.cyan.darken4,
      alarm: colors.deepOrange.darken4,
    },
    light: {
      primary: colors.cyan.darken2,
      secondary: colors.orange.darken2,
      accent: colors.purple.darken2,
      chart: colors.lightGreen.darken2,
      default: colors.blueGrey.lighten2,
      background: colors.grey.lighten4,
      menuBackground: "#ffffff",
      card: colors.blueGrey.lighten5,
      toolbar: colors.blueGrey.lighten3,
      info: colors.indigo.accent2,
      warning: colors.orange.darken2,
      success: colors.green.darken1,
      font: "#000",
      scrollbar: colors.grey.darken2,
      marked: colors.cyan.lighten3,
      alarm: colors.orange.lighten3,
    },
  },
  {
    name: "Pink",
    dark: {
      primary: colors.pink.darken1,
      secondary: colors.green.darken1,
      accent: colors.indigo.accent1,
      chart: colors.deepPurple.accent4,
      default: colors.blueGrey.lighten2,
      background: colors.grey.darken4,
      menuBackground: "#1e1e1e",
      card: "#322833",
      toolbar: "#AE8DB0",
      info: colors.indigo.accent2,
      warning: colors.orange.darken2,
      success: colors.green.darken1,
      font: "#fff",
      scrollbar: colors.grey.lighten1,
      marked: colors.pink.darken4,
      alarm: colors.deepOrange.darken4,
    },
    light: {
      primary: colors.pink.darken1,
      secondary: colors.green.darken1,
      accent: colors.blue.darken2,
      chart: colors.purple.lighten2,
      default: colors.blueGrey.lighten2,
      background: colors.grey.lighten4,
      menuBackground: "#ffffff",
      card: "#F2E1F1",
      toolbar: "#C3A5C4",
      info: colors.indigo.accent2,
      warning: colors.orange.darken2,
      success: colors.green.darken1,
      font: "#000",
      scrollbar: colors.grey.darken2,
      marked: colors.pink.lighten2,
      alarm: colors.orange.lighten3,
    },
  },
  {
    name: "Grün",
    dark: {
      primary: colors.lightGreen.darken1,
      secondary: colors.deepPurple.lighten1,
      accent: colors.teal.lighten2,
      chart: colors.green.accent4,
      default: colors.blueGrey.lighten2,
      background: colors.grey.darken4,
      menuBackground: "#1e1e1e",
      card: "#2D3328",
      toolbar: "#9BB08D",
      info: colors.indigo.accent2,
      warning: colors.orange.darken2,
      success: colors.green.darken1,
      font: "#fff",
      scrollbar: colors.grey.lighten1,
      marked: colors.lightGreen.darken4,
      alarm: colors.deepOrange.darken4,
    },
    light: {
      primary: colors.lightGreen.darken1,
      secondary: colors.deepPurple.lighten1,
      accent: colors.teal.darken2,
      chart: colors.green.lighten2,
      default: colors.blueGrey.lighten2,
      background: colors.grey.lighten4,
      menuBackground: "#ffffff",
      card: "#E6F2E1",
      toolbar: "#B1C4A5",
      info: colors.indigo.accent2,
      warning: colors.orange.darken2,
      success: colors.green.darken1,
      font: "#000",
      scrollbar: colors.grey.darken2,
      marked: colors.lightGreen.lighten2,
      alarm: colors.orange.lighten3,
    },
  },
];

export default (name) => {
  if(name === "Patient"){
    let theme = themes.find((e) => e.name === "Blau")
    theme.name = "Patient"
    return theme
  }
  let result = themes.find((e) => e.name === name);
  return result || themes[0];
};
