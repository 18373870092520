import mutations from "@/_evoli/store/form/mutations";
import actions from "@/_evoli/store/form/actions";
import getters from "@/_evoli/store/form/getters";

export default {
  namespaced: true,
  state: {
    values: {},
    errors: {},
    changedValues: {},
    ignoreValidation: {},
  },
  getters,
  mutations,
  actions,
};
