export default {
  getFormValues: (state) => (form) => {
    return state.values[form] || {}
  },
  getFormValue: (state) => (form, key) => {
    return state.values[form]?.[key]
  },
  getFormErrors: (state) => (form) => {
    return state.errors[form] || {}
  },
  getFormError: (state) => (form, key) => {
    return state.errors[form]?.[key]
  },
  getFormChangedValues: (state) => (form) => {
    return state.changedValues[form] || []
  },
}
