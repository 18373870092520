import permission from "@/_evoli/helper/permission";

export default (store) => {
  let result = [
    {
      text: "Status",
      value: "status"
    },
    {
      text: "Fallnummer",
      value: "referenceNumber"
    },
    {
      text: "Anfang",
      value: "createdAt"
    },
    {
      text: "Ziel",
      value: "dueDate"
    }
  ];

  if(!permission(store, ["ANON"])){
    result.push({
      text: "Abgeschlossen",
      value: "finishDate"
    },
    {
      text: "VNR / ANOGU / Id",
      value: "policyHolder.insuranceNumber"
    },
    {
      text: "Geschlecht",
      value: "policyHolder.gender",
      sortable: false,
    },
    {
      text: "Geburtsdatum",
      value: "policyHolder.birthDate",
      sortable: false,
    },
    {
      text: "Fachbereich",
      value: "subject.name"
    })
  } else {
    result.push({
      text: "Notiz",
      value: "note",
      sortable: false,
    })
  }

  if (permission(store, ["MEDEXO"])) {

    result.splice(3, 0, {
      text: "Versendet",
      value: "sendDate"
    })

    result.push(
      {
        text: "Experte",
        value: "expert",
        sortable: false,
      },
      {
        text: "Kostenträger",
        value: "costBearer.name"
      },
      {
        text: "Ansprechpartner",
        value: "contactPerson",
        sortable: false,
      },
      {
        text: "Sachbearbeiter",
        value: "supervisor",
        sortable: false,
      },
      {
        text: "Honorarpaket",
        value: "servicePackage.name",
      },
      {
        text: "Preispaket",
        value: "pricePackage.name",
      },
      {
        text: "Notiz",
        value: "note",
        sortable: false,
      },
      {
        text: "Seitenzahl",
        value: "documentPageCount",
        sortable: false,
      },
      {
        text: "Dauer",
        value: "duration",
        sortable: false,
      },
      {
        label: "Express",
        value: "express",
        sortable: false,
      },
      {
        label: "Reklamation",
        value: "reclaim",
        sortable: false,
      },
      {
        label: "Widerrufen",
        value: "cancellation",
        sortable: false,
      },
      {
        label: "Sonderhonorar",
        value: "specialFee",
        sortable: false,
      },
      {
        label: "Kopie",
        value: "preCaseReferenceNumber",
        sortable: false,
      },
      {
        label: "Zieldatum abgelaufen",
        value: "checkDueDate",
        sortable: false,
      },
      {
        label: "Fertiggestellt",
        value: "doneByCb",
        sortable: false,
      },
      {
        label: "Geöffnet",
        value: "openBy",
        sortable: false,
      }
    );
  }
  if (permission(store, ["COSTBEARER"])) {
    result.push({
      text: "Ansprechpartner",
      value: "contactPerson",
      sortable: false,
    },{
      label: "Fertiggestellt",
      value: "doneByCb",
      sortable: false,
    },{
      label: "Gutachten herunterladen",
      value: "opinionDocument",
      sortable: false
    },{
      label: "Express",
      value: "express",
      sortable: false,
    },{
      label: "Kopie",
      value: "preCaseReferenceNumber",
      sortable: false,
    },);
  }

  if(permission(store, ["EXPERT"])){
    result.push({
      label: "Zieldatum abgelaufen",
      value: "checkDueDate",
      sortable: false,
    },{
      label: "Express",
      value: "express",
      sortable: false,
    },{
      label: "Kopie",
      value: "preCaseReferenceNumber",
      sortable: false,
    },)
  }

  return result;
};
