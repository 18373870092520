import mutations from "@/_evoli/store/tickets/mutations";
import getters from "@/_evoli/store/tickets/getters";

export default {
  namespaced: true,
  state: { 
    tickets: []
   },
  getters,
  mutations,
};
